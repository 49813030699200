
import UsersActions from "@/components/users/Actions"
import UsersList from "@/components/users/List"
import { mapState } from 'vuex'

export default {
  name: "Users",
  components: {
    UsersActions,
    UsersList,
  },
  data() {
    return {
      mode: 'list',
      modes: [
        { title: 'List', code: 'list' },
        { title: 'Add', code: 'add' },
      ],
      userForAction: null,
      action: null,
    }
  },
  computed: {
    ...mapState('organizations', [
      'getOrganizationsResult',
      'getOrganizationsIsLoading',
      'getOrganizationsError',
    ]),
    ...mapState('users', [
      'getCurrentProfileResult',
      'getCurrentProfileIsLoading',
      'getCurrentProfileError',

      'getProfilesResult',
      'getProfilesIsLoading',
      'getProfilesError',

      'editProfilesItemResult',
      'editProfilesItemIsLoading',
      'editProfilesItemError',

      'getUsersResult',
      'getUsersIsLoading',
      'getUsersError',

      'createUsersItemResult',
      'createUsersItemIsLoading',
      'createUsersItemError',

      'editUsersItemResult',
      'editUsersItemIsLoading',
      'editUsersItemError',

      'removeUsersItemResult',
      'removeUsersItemIsLoading',
      'removeUsersItemError',
    ]),
    isLoading() {
      return this.getOrganizationsIsLoading ||
        this.getCurrentProfileIsLoading ||
        this.getProfilesIsLoading ||
        this.editProfilesItemIsLoading ||
        this.getUsersIsLoading ||
        this.createUsersItemIsLoading ||
        this.editUsersItemIsLoading ||
        this.removeUsersItemIsLoading;
    },
    combinedItems() {
      if (!this.getProfilesResult || !this.getUsersResult) {
        return [];
      }
      const result = [];
      this.getUsersResult.forEach(user => {
        let combinedItem = {
          ...user,
          user: user.id,
        };
        const relatedProfile = this.getProfilesResult.find(profile => profile.user === user.id);
        if (relatedProfile) {
          combinedItem = {
            ...combinedItem,
            ...relatedProfile,
            id: user.id,
            profile: relatedProfile.id,
            organization: this.getOrganizationsResult?.find(org => org.id === relatedProfile.organization) || null,
          };
        }
        result.push(combinedItem);
      });
      result.sort((a, b) => a.user > b.user ? -1 : 1);
      return result;
    }
  },
  methods: {
    setMode(newCode) {
      if (newCode === 'add') {
        this.userForAction = null;
        this.action = 'add';
      }
      this.mode = newCode;
    },
    deleteHandler(organization) {
      this.userForAction = organization;
      this.action = 'delete';
    },
    editHandler(organization) {
      this.userForAction = organization;
      this.action = 'edit';
    },
    cancelHandler() {
      this.userForAction = null;
      this.action = null;
      this.mode = 'list';
    },
  },
  beforeMount() {
    if (!this.getOrganizationsResult && !this.getOrganizationsIsLoading) {
      this.$store.dispatch('organizations/getOrganizations');
    }
    if (!this.getProfilesResult && !this.getProfilesIsLoading) {
      this.$store.dispatch('users/getProfiles');
    }
    if (!this.getUsersResult && !this.getUsersIsLoading) {
      this.$store.dispatch('users/getUsers');
    }
  },
  watch: {
    removeOrganizationsItemIsLoading(newVal, prevVal) {
      if (!newVal && prevVal) {
        this.$store.dispatch('organizations/getOrganizations');
      }
    }
  }
}
