
import Loader from '../Loader'

export default {
  name: "UsersList",
  components: {
    Loader,
  },
  props: {
    widgetClasses: String,
    items: Array,
    isLoading: Boolean,
  },
  emits: ['delete', 'edit', 'add'],
}
