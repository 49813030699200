
import Loader from '../Loader'
import { mapState } from 'vuex';

export default {
  name: 'UsersActions',
  components: {
    Loader,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    action: {
      type: String || null,
      default: null,
    },
    widgetClasses: String,
  },
  data() {
    return {
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      password1: '',
      password2: '',
      demo_account: false,
      organizationId: '',

      errorToShow: null,
      payloadForProfile: null,
    }
  },
  computed: {
    ...mapState('organizations', [
      'getOrganizationsResult',
      'getOrganizationsIsLoading',
      'getOrganizationsError',
    ]),
    ...mapState('users', [
      'createUsersItemResult',
      'createUsersItemIsLoading',
      'createUsersItemError',

      'editUsersItemResult',
      'editUsersItemIsLoading',
      'editUsersItemError',

      'removeUsersItemResult',
      'removeUsersItemIsLoading',
      'removeUsersItemError',

      'editProfilesItemResult',
      'editProfilesItemIsLoading',
      'editProfilesItemError',
    ]),
    title() {
      if (this.action === 'delete') {
        return 'Remove User';
      }
      if (this.action === 'edit') {
        return 'Edit User';
      }
      return 'Add User';
    },
    vendor() {
      return this.$store.state.users.getCurrentProfileResult.vendor;
    },
    organizationsOptions() {
      if (!this.getOrganizationsResult) {
        return [];
      }
      return this.getOrganizationsResult.map(org => ({
        title: org.name,
        value: org.id.toString(),
      }));
    },
    isLoading() {
      return this.getOrganizationsIsLoading ||
        this.createUsersItemIsLoading ||
        this.editUsersItemIsLoading ||
        this.removeUsersItemIsLoading ||
        this.editProfilesItemIsLoading;
    },
    error() {
      const error = this.getOrganizationsError ||
        this.createUsersItemError ||
        this.editUsersItemError ||
        this.removeUsersItemError ||
        this.editProfilesItemError;
      return error ? error.replace('password1', 'Password:').replace('password2', 'Password Repeat:') : error;
    },
    isUsernameValid() {
      return this.username && /^[a-zA-Z0-9\@\.\+\-\_]*$/g.test(this.username);
    },
    isEmailValid() {
      return this.email && this.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    },
    isPasswordComboValid() {
      return this.password1 === this.password2;
    },
    isPassword1Valid() {
      if (this.action === 'add') {
        return !!this.password1;
      }
      return true;
    },
    isPassword2Valid() {
      if (this.action === 'add') {
        return !!this.password2;
      }
      return true;
    },
    isValid() {
      if (
        !this.isUsernameValid ||
        !this.isEmailValid ||
        !this.isPasswordComboValid || !this.isPassword1Valid || !this.isPassword2Valid
        ) {
        return false;
      }
      return true;
    },
    isDisabled() {
      return this.isLoading || !this.isValid;
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const payload = {
        vendor: this.vendor,
        username: this.username,
        demo_account: this.demo_account,
        organization: this.organizationId,
        first_name: this.first_name,
        last_name: this.last_name,
      };
      if (this.email) {
        payload.email = this.email;
      }
      if (this.action === 'add' ||
        (this.password1 && this.password2)) {
        payload.password1 = this.password1;
        payload.password2 = this.password2;
      }
      if (this.item) {
        if (this.action === 'edit') {
          this.payloadForProfile = payload;
          this.$store.dispatch('users/editUsersItem', { ...this.item, ...payload });
        } else if (this.action === 'delete') {
          this.$store.dispatch('users/removeUsersItem', this.item);
        }
      } else {
        this.payloadForProfile = payload;
        this.$store.dispatch('users/createUsersItem', payload);
      }
    },
    actionSuccessHandler() {
      this.$emit('cancel');
      this.$store.dispatch('users/getUsers');
      this.$store.dispatch('users/getProfiles');
    }
  },
  watch: {
    item: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.username = newVal.username;
          if (newVal.first_name) {
            this.first_name = newVal.first_name;
          }
          if (newVal.last_name) {
            this.last_name = newVal.last_name;
          }
          if (newVal.email) {
            this.email = newVal.email;
          }
          if (newVal.demo_account) {
            this.demo_account = newVal.demo_account;
          }
          if (newVal.organization) {
            this.organizationId = newVal.organization.id.toString();
          }
        } else {
          this.username = '';
          this.first_name = '';
          this.last_name = '';
          this.email = '';
          this.demo_account = false;
          this.organizationId = '';
        }
      }
    },
    isLoading(newVal) {
      if (newVal) {
        this.errorToShow = null;
      } else if (this.error) {
        this.errorToShow = this.error;
      }
    },
    createUsersItemIsLoading(newVal) {
      if (!newVal && this.createUsersItemResult) {
        this.$store.dispatch('users/editProfilesItem', { profile: this.createUsersItemResult.profile, ...this.payloadForProfile });
        this.payloadForProfile = null;
      }
    },
    editUsersItemIsLoading(newVal) {
      if (!newVal && this.editUsersItemResult) {
        this.$store.dispatch('users/editProfilesItem', { profile: this.editUsersItemResult.profile, ...this.payloadForProfile });
        this.payloadForProfile = null;
      }
    },
    removeUsersItemIsLoading(newVal) {
      if (!newVal && this.removeUsersItemResult) {
        this.actionSuccessHandler();
      }
    },
    editProfilesItemIsLoading(newVal) {
      if (!newVal && this.editProfilesItemResult) {
        this.actionSuccessHandler();
      }
    },
  }
}
